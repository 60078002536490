import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@bbc-account/id-components';
import { errorShape } from '../../propTypes';
import CassoText from '../shared/cassoText';
import Form from '../shared/form';
import FormError from '../shared/formError';
import MarketingOptIn from '../shared/inputFields/marketingOptInField';
import Message from '../shared/message';
import formNames from '../../../shared/data/formNames';
import {
    REGISTER_THANKS_PATH,
    REGISTER_FEDERATED_THANKS_PATH,
} from '../../../shared/endpoints/account';
import { useStateContext } from '../../modules/stateContext';
import Layout from '../pageLayout/layout';
import Track from '../shared/analytics/track';

const UkMarketingPreferenceForm = ({ initialErrors = {} }) => {
    const {
        isFederated,
        mvt,
        featureToggles: { otpVerification },
    } = useStateContext();

    const variantName = mvt?.experiments?.acc_sireve_reg_email_verification;

    const isBucketedInOtpVariant =
        variantName === 'after_email' || variantName === 'after_details';

    const registerThanksPath = isFederated
        ? REGISTER_FEDERATED_THANKS_PATH
        : REGISTER_THANKS_PATH;

    const formError =
        initialErrors.general !== true ? initialErrors.general : undefined;

    const headingTranslationKey = isFederated
        ? 'register.title.confirmation.overThirteenNoSignIn'
        : 'common.newsletterOptIn.title';

    return (
        <Layout
            showServiceIdentifiers
            heading={<FormattedMessage id={headingTranslationKey} />}
        >
            <FormError name="general" error={formError} isAssertive />
            <Form action={registerThanksPath} method="post">
                <MarketingOptIn error={initialErrors.marketingOptIn} />

                {(!otpVerification || !isBucketedInOtpVariant) && (
                    <Track name="info-message">
                        <Message
                            name="info"
                            className="form-message--info-icon form-message--with-icon"
                        >
                            <FormattedMessage id="emailVerification.checkInboxMvt" />
                        </Message>
                    </Track>
                )}

                <CassoText additionalClasses="u-margin-top-quad" />
                <Button isFullWidth isSubmit id="submit-button">
                    <FormattedMessage id="button.continue.value" />
                </Button>
            </Form>
        </Layout>
    );
};

UkMarketingPreferenceForm.displayName = 'UkMarketingPreferenceForm';

UkMarketingPreferenceForm.propTypes = {
    ...reduxFormPropTypes,
    initialErrors: PropTypes.shape({
        marketingOptIn: errorShape,
        general: errorShape,
    }),
};

const mapStateToProps = ({ registerThanks }) => ({
    initialErrors: registerThanks.initialErrors || {},
});

export default connect(mapStateToProps)(
    reduxForm({
        form: formNames.REGISTER_THANKS,
        destroyOnUnmount: false,
    })(UkMarketingPreferenceForm)
);
