import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@bbc-account/id-components';
import AuthForm from '../form';
import Layout from '../../pageLayout/layout';
import PasswordInput from '../inputs/password';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../modules/stateContext';
import useJsEnabled from '../../../hooks/useJsEnabled';
import Text from '../../shared/text';
import usePreventFormResubmission from '../../../hooks/usePreventFormResubmission';
import {
    AUTH_PASSWORD_PATH,
    CREATE_PASSWORD_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from '../../../../shared/endpoints/account';
import { HelpLinks } from '../helpLinks';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../../forms';
import MagicLinkCta from '../../shared/magicLinkCta';

const MagicLinkCopy = () => {
    const userContext = useStateContext();
    usePreventFormResubmission();

    const {
        location: { isUk },
        signIn: {
            context: { hasPassword },
            data: { username },
        },
        featureToggles: { checkHasPassword },
    } = userContext;

    const headingTitle = useMemo(() => {
        if (isUk || checkHasPassword === false || hasPassword === true) {
            return 'auth.magicLink.cta.hasPassword.title';
        }

        return 'auth.magicLink.cta.noPassword.title';
    }, [checkHasPassword, isUk, hasPassword]);

    return (
        <div className="u-margin-top-quad u-margin-bottom-penta">
            <Heading fontScale="indexHeadlineMedium" level={2}>
                <FormattedMessage id={headingTitle} />
            </Heading>
            <Text className="u-margin-top">
                <FormattedMessage id="auth.magicLink.cta.text" />
            </Text>
            <MagicLinkCta email={username} />
        </div>
    );
};

const forgottenPasswordLink = {
    href: FORGOTTEN_PASSWORD_PATH,
    label: 'cama.link.forgotPassword',
};

const createPasswordLink = {
    href: CREATE_PASSWORD_PATH,
    label: 'auth.password.create.label',
};

const PasswordForm = () => {
    const userContext = useStateContext();
    const jsEnabled = useJsEnabled();

    const {
        location: { isUk },
        signIn: {
            context: { hasPassword, magicLinkEnabled },
            data,
        },
        featureToggles: {
            checkHasPassword,
            createPasswordContent,
            persistentIdentifier,
        },
    } = userContext;

    const { sessionStorage } = useSessionStorage();

    const shouldHideResetPasswordLink = magicLinkEnabled;

    useEffect(() => {
        if (persistentIdentifier && data?.username) {
            sessionStorage?.setItem(
                ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
                data.username
            );
        }
    }, []);

    const helpLinksItems = useMemo(() => {
        const items = [];

        if (isUk) {
            if (!shouldHideResetPasswordLink) {
                items.push(forgottenPasswordLink);
            }
        } else if (!checkHasPassword || hasPassword) {
            items.push(forgottenPasswordLink);
        } else if (createPasswordContent) {
            items.push(createPasswordLink);
        }

        return items;
    }, [
        checkHasPassword,
        createPasswordContent,
        hasPassword,
        isUk,
        shouldHideResetPasswordLink,
    ]);

    const passwordAction = `${AUTH_PASSWORD_PATH}${renderQueryString.call(
        userContext,
        jsEnabled === false ? { jsEnabled: 'false' } : {}
    )}`;

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.password.title.introduction" />}
            showServiceIdentifiers
        >
            <AuthForm
                action={passwordAction}
                buttonTranslationKey="signIn.button.submit.value"
            >
                <input name="hasPassword" value={hasPassword} type="hidden" />
                <PasswordInput />
            </AuthForm>
            {magicLinkEnabled && <MagicLinkCopy />}

            <HelpLinks className="button__label" items={helpLinksItems} />
        </Layout>
    );
};

export default PasswordForm;
